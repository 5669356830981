import consumer from "./consumer"

document.addEventListener('turbolinks:load', () => {

  const element = document.getElementById('game-id');
  const game_id = element.getAttribute('data-game-id');

  consumer.subscriptions.create({channel: "GameChannel", game_id: game_id }, 
    {
    connected() {
      console.log("connected to game channel "+ game_id)
      // Called when the subscription is ready for use on the server
    },
  
    disconnected() {
      // Called when the subscription has been terminated by the server
    },
  
    received(data) {
      console.log(data)
      updategame()
      // Called when there's incoming data on the websocket for this channel
    }
  });
})




